@font-face {
  font-family: 'RenaultIcon';
  src:  url('fonts/RenaultIcon.eot?otdms');
  src:  url('fonts/RenaultIcon.eot?otdms#iefix') format('embedded-opentype'),
    url('fonts/RenaultIcon.ttf?otdms') format('truetype'),
    url('fonts/RenaultIcon.woff?otdms') format('woff'),
    url('fonts/RenaultIcon.svg?otdms#RenaultIcon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'RenaultIcon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-read:before {
  content: "\ea34";
}
.icon-log_out:before {
  content: "\ea35";
}
.icon-save:before {
  content: "\e900";
}
.icon-print:before {
  content: "\e901";
}
.icon-send:before {
  content: "\e902";
}
.icon-file_download:before {
  content: "\e903";
}
.icon-folder:before {
  content: "\e904";
}
.icon-files:before {
  content: "\e905";
}
.icon-sheet:before {
  content: "\e906";
}
.icon-list_sheet:before {
  content: "\e907";
}
.icon-faourite_sheet:before {
  content: "\e908";
}
.icon-car_sheet:before {
  content: "\e909";
}
.icon-email_sheet:before {
  content: "\e90a";
}
.icon-pdf_sheet:before {
  content: "\e90b";
}
.icon-settings_sheet:before {
  content: "\e90c";
}
.icon-cookies:before {
  content: "\e90d";
}
.icon-usb:before {
  content: "\e90e";
}
.icon-reload:before {
  content: "\e90f";
}
.icon-refresh:before {
  content: "\e910";
}
.icon-edit:before {
  content: "\e911";
}
.icon-check_list:before {
  content: "\e912";
}
.icon-bullet_list:before {
  content: "\e913";
}
.icon-check_list_ok:before {
  content: "\e914";
}
.icon-ok:before {
  content: "\e915";
}
.icon-no:before {
  content: "\e916";
}
.icon-delete:before {
  content: "\e917";
}
.icon-apps:before {
  content: "\e918";
}
.icon-app:before {
  content: "\e919";
}
.icon-home:before {
  content: "\e91a";
}
.icon-settings:before {
  content: "\e91b";
}
.icon-filter_1:before {
  content: "\e91c";
}
.icon-filter_2:before {
  content: "\e91d";
}
.icon-favourite:before {
  content: "\e91e";
}
.icon-bookmark:before {
  content: "\e91f";
}
.icon-new_bookmark:before {
  content: "\e920";
}
.icon-view:before {
  content: "\e921";
}
.icon-search:before {
  content: "\e922";
}
.icon-zoom:before {
  content: "\e923";
}
.icon-de-zoom:before {
  content: "\e924";
}
.icon-increase_text:before {
  content: "\e925";
}
.icon-decrease_text:before {
  content: "\e926";
}
.icon-touch:before {
  content: "\e927";
}
.icon-menu:before {
  content: "\e928";
}
.icon-add:before {
  content: "\e929";
}
.icon-more:before {
  content: "\e92a";
}
.icon-alert:before {
  content: "\e92b";
}
.icon-information:before {
  content: "\e92c";
}
.icon-question:before {
  content: "\e92d";
}
.icon-comment:before {
  content: "\e92e";
}
.icon-dialog:before {
  content: "\e92f";
}
.icon-user:before {
  content: "\e930";
}
.icon-discussion:before {
  content: "\e931";
}
.icon-group:before {
  content: "\e932";
}
.icon-contact:before {
  content: "\e933";
}
.icon-idea:before {
  content: "\e934";
}
.icon-photo:before {
  content: "\e935";
}
.icon-video:before {
  content: "\e936";
}
.icon-play:before {
  content: "\e937";
}
.icon-pause:before {
  content: "\e938";
}
.icon-stop:before {
  content: "\e939";
}
.icon-previous:before {
  content: "\e93a";
}
.icon-next:before {
  content: "\e93b";
}
.icon-minimize:before {
  content: "\e93c";
}
.icon-full_screen:before {
  content: "\e93d";
}
.icon-expand:before {
  content: "\e93e";
}
.icon-soud:before {
  content: "\e93f";
}
.icon-no_sound:before {
  content: "\e940";
}
.icon-music:before {
  content: "\e941";
}
.icon-podcast:before {
  content: "\e942";
}
.icon-download:before {
  content: "\e943";
}
.icon-upload:before {
  content: "\e944";
}
.icon-links:before {
  content: "\e945";
}
.icon-link_out:before {
  content: "\e946";
}
.icon-share_1:before {
  content: "\e947";
}
.icon-share_2:before {
  content: "\e948";
}
.icon-arrow_1_left:before {
  content: "\e949";
}
.icon-arrow_1_right:before {
  content: "\e94a";
}
.icon-arrow_1_up:before {
  content: "\e94b";
}
.icon-arrow_1_down:before {
  content: "\e94c";
}
.icon-arrow_1_up_left:before {
  content: "\e94d";
}
.icon-arrow_1_up_right:before {
  content: "\e94e";
}
.icon-arrow_1_down_right:before {
  content: "\e94f";
}
.icon-arrow_1_down_left:before {
  content: "\e950";
}
.icon-arrow_2_left:before {
  content: "\e951";
}
.icon-arrow_2_right:before {
  content: "\e952";
}
.icon-arrow_2_up:before {
  content: "\e953";
}
.icon-arrow_2_down:before {
  content: "\e954";
}
.icon-arrow_2_up_left:before {
  content: "\e955";
}
.icon-arrow_2_up_right:before {
  content: "\e956";
}
.icon-arrow_2_down_right:before {
  content: "\e957";
}
.icon-arrow_2_down_left:before {
  content: "\e958";
}
.icon-slide:before {
  content: "\e959";
}
.icon-scroll:before {
  content: "\e95a";
}
.icon-phone:before {
  content: "\e95b";
}
.icon-mobile:before {
  content: "\e95c";
}
.icon-tablet:before {
  content: "\e95d";
}
.icon-laptop:before {
  content: "\e95e";
}
.icon-desktop:before {
  content: "\e95f";
}
.icon-television:before {
  content: "\e960";
}
.icon-wifi:before {
  content: "\e961";
}
.icon-car-send:before {
  content: "\e962";
}
.icon-car_received:before {
  content: "\e963";
}
.icon-car_ko:before {
  content: "\e964";
}
.icon-car_ok:before {
  content: "\e965";
}
.icon-calendar:before {
  content: "\e966";
}
.icon-reminder:before {
  content: "\e967";
}
.icon-alarm:before {
  content: "\e968";
}
.icon-stopwatch:before {
  content: "\e969";
}
.icon-answer_in_4_hours:before {
  content: "\e96a";
}
.icon-data:before {
  content: "\e96b";
}
.icon-chart:before {
  content: "\e96c";
}
.icon-organisation:before {
  content: "\e96d";
}
.icon-planet:before {
  content: "\e96e";
}
.icon-factory:before {
  content: "\e96f";
}
.icon-foundy:before {
  content: "\e970";
}
.icon-basket:before {
  content: "\e971";
}
.icon-credit_card:before {
  content: "\e972";
}
.icon-euro:before {
  content: "\e973";
}
.icon-money:before {
  content: "\e974";
}
.icon-coins:before {
  content: "\e975";
}
.icon-reset_coins:before {
  content: "\e976";
}
.icon-saing:before {
  content: "\e977";
}
.icon-calculator:before {
  content: "\e978";
}
.icon-promo:before {
  content: "\e979";
}
.icon-sales:before {
  content: "\e97a";
}
.icon-shop_bag:before {
  content: "\e97b";
}
.icon-box:before {
  content: "\e97c";
}
.icon-car_servicing:before {
  content: "\e97d";
}
.icon-welcom:before {
  content: "\e97e";
}
.icon-carrestitution:before {
  content: "\e97f";
}
.icon-car_dealership:before {
  content: "\e980";
}
.icon-safety:before {
  content: "\e981";
}
.icon-tailored:before {
  content: "\e982";
}
.icon-flag:before {
  content: "\e983";
}
.icon-target:before {
  content: "\e984";
}
.icon-pannel:before {
  content: "\e985";
}
.icon-compas:before {
  content: "\e986";
}
.icon-pin:before {
  content: "\e987";
}
.icon-motorway:before {
  content: "\e988";
}
.icon-countryside:before {
  content: "\e989";
}
.icon-city:before {
  content: "\e98a";
}
.icon-parking:before {
  content: "\e98b";
}
.icon-mobility_help:before {
  content: "\e98c";
}
.icon-tap_gesture_1:before {
  content: "\e98d";
}
.icon-tap_gesture_2:before {
  content: "\e98e";
}
.icon-tap_gesture_3:before {
  content: "\e98f";
}
.icon-double_tab_gesture_1:before {
  content: "\e990";
}
.icon-double_tab_gesture_2:before {
  content: "\e991";
}
.icon-double_tab_gesture_3:before {
  content: "\e992";
}
.icon-swipe_gesture_1:before {
  content: "\e993";
}
.icon-swipe_gesture_2:before {
  content: "\e994";
}
.icon-swipe_gesture_3:before {
  content: "\e995";
}
.icon-hold_gesture_1:before {
  content: "\e996";
}
.icon-hold_gesture_2:before {
  content: "\e997";
}
.icon-hold_gesture_3:before {
  content: "\e998";
}
.icon-scroll_gesture_1:before {
  content: "\e999";
}
.icon-scroll_gesture_2:before {
  content: "\e99a";
}
.icon-scroll_gesture_3:before {
  content: "\e99b";
}
.icon-scale_gesture_1:before {
  content: "\e99c";
}
.icon-scale_gesture_2:before {
  content: "\e99d";
}
.icon-scale_gesture_3:before {
  content: "\e99e";
}
.icon-drag_gesture_1:before {
  content: "\e99f";
}
.icon-drag_gesture_2:before {
  content: "\e9a0";
}
.icon-drag_gesture_3:before {
  content: "\e9a1";
}
.icon-split_and_rotate_gesture_1:before {
  content: "\e9a2";
}
.icon-split_and_rotate_gesture_2:before {
  content: "\e9a3";
}
.icon-split_and_rotate_gesture_3:before {
  content: "\e9a4";
}
.icon-eco_2_chart:before {
  content: "\e9a5";
}
.icon-eco_2:before {
  content: "\e9a6";
}
.icon-fuel_station:before {
  content: "\e9a7";
}
.icon-fuel_excellent:before {
  content: "\e9a8";
}
.icon-fuel_very_good:before {
  content: "\e9a9";
}
.icon-fuel_good:before {
  content: "\e9aa";
}
.icon-fuel_low:before {
  content: "\e9ab";
}
.icon-fuel_very_low:before {
  content: "\e9ac";
}
.icon-no_fuel:before {
  content: "\e9ad";
}
.icon-fuel_compare:before {
  content: "\e9ae";
}
.icon-hybrid:before {
  content: "\e9af";
}
.icon-plugging_hybrid:before {
  content: "\e9b0";
}
.icon-battery:before {
  content: "\e9b1";
}
.icon-battery_leasing:before {
  content: "\e9b2";
}
.icon-electric_power:before {
  content: "\e9b3";
}
.icon-electric_plug:before {
  content: "\e9b4";
}
.icon-not_plugged:before {
  content: "\e9b5";
}
.icon-plugged_ok:before {
  content: "\e9b6";
}
.icon-standard_charging_port:before {
  content: "\e9b7";
}
.icon-rapide_charging_port:before {
  content: "\e9b8";
}
.icon-wall_charging_port:before {
  content: "\e9b9";
}
.icon-electric_vehicule:before {
  content: "\e9ba";
}
.icon-battery_excellent:before {
  content: "\e9bb";
}
.icon-battery_very_good:before {
  content: "\e9bc";
}
.icon-battery_good:before {
  content: "\e9bd";
}
.icon-battery_low:before {
  content: "\e9be";
}
.icon-battery_very_low:before {
  content: "\e9bf";
}
.icon-no_battery:before {
  content: "\e9c0";
}
.icon-engine:before {
  content: "\e9c1";
}
.icon-electric_engine:before {
  content: "\e9c2";
}
.icon-kw_engine:before {
  content: "\e9c3";
}
.icon-oil_engine:before {
  content: "\e9c4";
}
.icon-engine_capacity:before {
  content: "\e9c5";
}
.icon-car:before {
  content: "\e9c6";
}
.icon-car_park:before {
  content: "\e9c7";
}
.icon-car_range:before {
  content: "\e9c8";
}
.icon-hatchback:before {
  content: "\e9c9";
}
.icon-sedan:before {
  content: "\e9ca";
}
.icon-etate:before {
  content: "\e9cb";
}
.icon-mpv:before {
  content: "\e9cc";
}
.icon-suv:before {
  content: "\e9cd";
}
.icon-pick-up:before {
  content: "\e9ce";
}
.icon-coupe:before {
  content: "\e9cf";
}
.icon-roadster:before {
  content: "\e9d0";
}
.icon-race:before {
  content: "\e9d1";
}
.icon-passenger_cdv:before {
  content: "\e9d2";
}
.icon-cdv:before {
  content: "\e9d3";
}
.icon-shuttle:before {
  content: "\e9d4";
}
.icon-compact_van:before {
  content: "\e9d5";
}
.icon-minibus:before {
  content: "\e9d6";
}
.icon-large_van:before {
  content: "\e9d7";
}
.icon-chassis-cab:before {
  content: "\e9d8";
}
.icon-dump_truck:before {
  content: "\e9d9";
}
.icon-campervan:before {
  content: "\e9da";
}
.icon-acceleration:before {
  content: "\e9db";
}
.icon-braking:before {
  content: "\e9dc";
}
.icon-prgression:before {
  content: "\e9dd";
}
.icon-renault_rent:before {
  content: "\e9de";
}
.icon-second_hand:before {
  content: "\e9df";
}
.icon-refresh_configuration:before {
  content: "\e9e0";
}
.icon-start_again:before {
  content: "\e9e1";
}
.icon-interior:before {
  content: "\e9e2";
}
.icon-custom:before {
  content: "\e9e3";
}
.icon-favourite_car:before {
  content: "\e9e4";
}
.icon-intervention:before {
  content: "\e9e5";
}
.icon-mergency:before {
  content: "\e9e6";
}
.icon-crash:before {
  content: "\e9e7";
}
.icon-breakdown:before {
  content: "\e9e8";
}
.icon-car_elevator:before {
  content: "\e9e9";
}
.icon-car_review:before {
  content: "\e9ea";
}
.icon-draining:before {
  content: "\e9eb";
}
.icon-mechanical_1:before {
  content: "\e9ec";
}
.icon-mechanical_2:before {
  content: "\e9ed";
}
.icon-spray_gun:before {
  content: "\e9ee";
}
.icon-paint_bucket:before {
  content: "\e9ef";
}
.icon-spray:before {
  content: "\e9f0";
}
.icon-spare_parts:before {
  content: "\e9f1";
}
.icon-car_body:before {
  content: "\e9f2";
}
.icon-type_and_rim:before {
  content: "\e9f3";
}
.icon-braking_1:before {
  content: "\e9f4";
}
.icon-tyre:before {
  content: "\e9f5";
}
.icon-check_tyre:before {
  content: "\e9f6";
}
.icon-pumping:before {
  content: "\e9f7";
}
.icon-damper:before {
  content: "\e9f8";
}
.icon-piston:before {
  content: "\e9f9";
}
.icon-double_piston:before {
  content: "\e9fa";
}
.icon-timing_belt:before {
  content: "\e9fb";
}
.icon-oil_filter:before {
  content: "\e9fc";
}
.icon-exhaust:before {
  content: "\e9fd";
}
.icon-check_oil:before {
  content: "\e9fe";
}
.icon-cooling_liquid:before {
  content: "\e9ff";
}
.icon-steering_wheel:before {
  content: "\ea00";
}
.icon-gearbox_1:before {
  content: "\ea01";
}
.icon-gearbox_2:before {
  content: "\ea02";
}
.icon-gearbox_3:before {
  content: "\ea03";
}
.icon-gearbox_4:before {
  content: "\ea04";
}
.icon-weight:before {
  content: "\ea05";
}
.icon-keys:before {
  content: "\ea06";
}
.icon-sellerie:before {
  content: "\ea07";
}
.icon-air_conditionning:before {
  content: "\ea08";
}
.icon-speeometer:before {
  content: "\ea09";
}
.icon-windshield:before {
  content: "\ea0a";
}
.icon-windshield_wipers:before {
  content: "\ea0b";
}
.icon-windshield_wipers_ok:before {
  content: "\ea0c";
}
.icon-door:before {
  content: "\ea0d";
}
.icon-drame:before {
  content: "\ea0e";
}
.icon-snow_box:before {
  content: "\ea0f";
}
.icon-box_1:before {
  content: "\ea10";
}
.icon-luggages:before {
  content: "\ea11";
}
.icon-payload:before {
  content: "\ea12";
}
.icon-bike:before {
  content: "\ea13";
}
.icon-headlights:before {
  content: "\ea14";
}
.icon-dimmed_headlights:before {
  content: "\ea15";
}
.icon-front_fog_lights:before {
  content: "\ea16";
}
.icon-no_lights:before {
  content: "\ea17";
}
.icon-very_happy:before {
  content: "\ea18";
}
.icon-happy:before {
  content: "\ea19";
}
.icon-undecided:before {
  content: "\ea1a";
}
.icon-not_happy:before {
  content: "\ea1b";
}
.icon-angry:before {
  content: "\ea1c";
}
.icon-surprise:before {
  content: "\ea1d";
}
.icon-cool:before {
  content: "\ea1e";
}
.icon-garantee:before {
  content: "\ea1f";
}
.icon-prise:before {
  content: "\ea20";
}
.icon-helmet:before {
  content: "\ea21";
}
.icon-joystick:before {
  content: "\ea22";
}
.icon-sunny:before {
  content: "\ea23";
}
.icon-windy:before {
  content: "\ea24";
}
.icon-cloudy_1:before {
  content: "\ea25";
}
.icon-cloudy_2:before {
  content: "\ea26";
}
.icon-raining_1:before {
  content: "\ea27";
}
.icon-raining_2:before {
  content: "\ea28";
}
.icon-lightning:before {
  content: "\ea29";
}
.icon-partly_cloudy_1:before {
  content: "\ea2a";
}
.icon-partly_cloudy_2:before {
  content: "\ea2b";
}
.icon-partly_raining_1:before {
  content: "\ea2c";
}
.icon-artly_raining_2:before {
  content: "\ea2d";
}
.icon-partly_lightning_1:before {
  content: "\ea2e";
}
.icon-partly_lightning_2:before {
  content: "\ea2f";
}
.icon-snowing:before {
  content: "\ea30";
}
.icon-partly_snowing_1:before {
  content: "\ea31";
}
.icon-partly_snowing_2:before {
  content: "\ea32";
}
.icon-snowy:before {
  content: "\ea33";
}
